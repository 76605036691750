import { isValid, addHours, isDate, format, addMinutes } from 'date-fns'

export const normalizeCurrency = (value: any, styled = false): string => {
  if (!value && value !== 0) {
    return value
  }

  const isNegative = Number(value) < 0

  if (Number(value) === 0) {
    return `${styled ? 'R$ ' : ''}0,00`
  }

  const parsedValue = Number(String(value).replace(/\D/g, '')) / 100

  if (styled) {
    return isNegative
      ? `- ${parsedValue.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        })}`
      : parsedValue.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        })
  }

  return isNegative
    ? `- ${parsedValue.toLocaleString('pt-br', {
        minimumFractionDigits: 2
      })}`
    : parsedValue.toLocaleString('pt-br', {
        minimumFractionDigits: 2
      })
}

export const normalizeDecimal = (value: any, precision?: number): string => {
  if (!value) {
    return value
  }

  precision = precision || 4

  const stringOfNumber = String(Number(normalizeNumber(value)))

  if (stringOfNumber === '0') {
    return '0'
  }

  if (stringOfNumber.length <= precision) {
    let result = '0.'
    const reversed = stringOfNumber.split('').reverse().join('')
    for (let i = precision - 1; i >= 0; i -= 1) {
      result += reversed[i] || '0'
    }
    return result
  }
  const cents = stringOfNumber.slice(
    stringOfNumber.length - precision,
    stringOfNumber.length
  )
  const reals = stringOfNumber.slice(0, stringOfNumber.length - precision)

  return `${reals}.${cents}`
}

export const subString = (value: string, max = 100): string => {
  if (String(value).length > max) {
    return `${String(value).slice(0, max - 3)}...`
  }
  return value
}

export const normalizePercentage = (
  value: string,
  locale = 'pt-br'
): string => {
  if (!value) {
    return value
  }

  const parsedValue = Number(value.replace(/\D/g, '')) / 100

  if (parsedValue > 10000) {
    return value
  }

  return parsedValue.toLocaleString(locale, {
    minimumFractionDigits: 2
  })
}
export const normalizeAccBank = (value: string): string => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/\D/g, '')

  if (onlyNums.length === 1) {
    return onlyNums
  }

  return `${onlyNums.slice(0, onlyNums.length - 1)}-${onlyNums.slice(
    onlyNums.length - 1,
    onlyNums.length
  )}`
}

export const normalizeNumber = (value: string): string => {
  if (!value) {
    return value
  }

  const onlyNums = String(value).replace(/\D/g, '')

  return onlyNums
}

export const normalizeNumbersAndSpecialCharacters = (value: string): string => {
  if (!value) {
    return value
  }

  const only = value.replace(/[^0-9-_/]/g, '')

  return only
}

export const normalizeCnpj = (value: string): string => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 2) {
    return onlyNums
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}`
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}`
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`
}

export const normalizeCpf = (value: string): string => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`
  }
  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
    6,
    9
  )}-${onlyNums.slice(9, 11)}`
}

export const normalizeCpfCnpj = (value: string): string => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`
  }
  if (onlyNums.length <= 11) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}-${onlyNums.slice(9, 11)}`
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}`
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`
}

export const normalizeNewDate = (value: string): string => {
  if (!value) {
    return value
  }

  return `${value.slice(6, 10)}-${value.slice(3, 5)}-${
    Number(value.slice(0, 2)) + 1
  }`
}

export const normalizeDate = (value: string): string => {
  if (!value) {
    return value
  }

  const formated = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})\d+?$/, '$1')

  return formated
}

export const normalizeDateTime = (
  value: string,
  format?: 'datetime' | 'date'
): string => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/\D/g, '')

  if (format === 'date') {
    return `${onlyNums.slice(6, 8)}/${onlyNums.slice(4, 6)}/${onlyNums.slice(
      0,
      4
    )}`
  } else {
    return `${onlyNums.slice(6, 8)}/${onlyNums.slice(4, 6)}/${onlyNums.slice(
      0,
      4
    )} ${onlyNums.slice(8, 10)}:${onlyNums.slice(10, 12)}:${onlyNums.slice(
      12,
      14
    )}`
  }
}

export const normalizeCep = (value: string): string => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 5) {
    return onlyNums
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`
}

export const normalizePhone = (value: string): string => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length === 0) {
    return ''
  }

  if (onlyNums.length <= 2) {
    return `(${onlyNums.slice(0, 2)}`
  }

  if (onlyNums.length <= 6 && onlyNums.length > 2) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}`
  }

  if (onlyNums.length > 6 && onlyNums.length < 11) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(
      6,
      10
    )}`
  }

  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
    7,
    11
  )}`
}

export const normalizeCmc7 = (value: string): string => {
  const onlyNums = value.split('<').join('').split('>').join('')
  if (onlyNums.length === 0) {
    return ''
  }
  if (onlyNums.length <= 8) {
    return `${'<'}${onlyNums}`
  }
  if (onlyNums.length <= 18) {
    return `${'<'}${onlyNums.slice(0, 8)}${'<'}${onlyNums.slice(8, 18)}`
  }
  return `${'<'}${onlyNums.slice(0, 8)}${'<'}${onlyNums.slice(
    8,
    18
  )}${'>'}${onlyNums.slice(18, 31)}`
}

export const stringToDate = (value: any): Date | boolean => {
  if (!value) {
    return false
  }

  if (isDate(value)) {
    return value
  }

  if (value.length < 10) {
    return false
  }

  const d = value.split('/')

  const date = new Date(`${d[2]}-${d[1]}-${d[0]}`)

  if (isValid(date)) {
    return addHours(date, 3)
  }

  return false
}

export const dateIsValid = (value: string | Date): boolean => {
  if (!value) {
    return false
  }
  try {
    if (isDate(new Date(value))) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

export const neutralizeDate = (value: Date): Date => {
  if (!value || !dateIsValid(value)) return value as Date

  const userDate = new Date(value)

  const userTimezoneOffset = userDate.getTimezoneOffset()
  const targetTimezoneOffset = 180

  if (userTimezoneOffset !== targetTimezoneOffset) {
    const diff = userTimezoneOffset - targetTimezoneOffset

    const convertedDate = addMinutes(userDate, diff)

    return convertedDate
  }

  return new Date(value)
}

export const formatDate = (value: any, hours?: boolean): string => {
  try {
    const d = dateIsValid(value)
    if (d) {
      if (!hours) {
        const iso = new Date(value).toISOString()
        const [year, month, day] = iso.split('T')[0]?.split('-')
        return `${day}/${month}/${year}`
      }
      const date = new Date(value).toLocaleString('pt-BR', {
        timeZone: 'America/Recife',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: hours ? 'numeric' : undefined,
        minute: hours ? 'numeric' : undefined
      })

      return date
    }
    return ''
  } catch (err) {
    return ''
  }
}

export const formatDateToString = (date: Date): string => {
  return format(date, 'dd/MM/yyyy')
}

export function formatCnpjRoot(root: string): string {
  if (root?.length === 8) {
    return `${root.slice(0, 2)}.${root.slice(2, 5)}.${root.slice(5)}`
  }
  return root
}

export function normalizeFileName(fileName: string): string {
  return fileName
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-')
}
